<template>
  <div v-if="isBannerVisible" class="alert">
    <!-- <font-awesome-icon :icon="['fas', 'cookie-bite']" size="sm" class="icon" /> -->
    <div class="alert-title">We value your privacy</div>
    <div class="alert-description">
      This site only uses essential cookies.
      <button type="button" @click="handleAccept" class="accept-button">
        Ok
      </button>
    </div>
  </div>
</template>

<script>
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import { faCookieBite } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'CookiesConsent',
  components: {
    // FontAwesomeIcon,
  },
  data() {
    return {
      isBannerVisible: false,
    };
  },
  mounted() {
    const cookiesConsent = sessionStorage.getItem('cookiesConsent');
    if (!cookiesConsent) {
      this.isBannerVisible = true;
    }
  },
  methods: {
    handleAccept() {
      sessionStorage.setItem('cookiesConsent', 'true');
      this.isBannerVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  width: fit-content;
  position: fixed;
  bottom: 1.25rem; /* bottom-5 in Tailwind */
  left: 1.25rem; /* left-5 in Tailwind */
  cursor: pointer;
  color: #334155; /* Slate-700 */
  font-family: 'Fancy Font', sans-serif; /* Adjust this to your chosen font */
  background-color: #f1f5f9; /* Slate-100 */
  color: #15803d; /* Success color */
  padding: 0.75rem; /* p-3 in Tailwind */
  border-radius: 0.5rem; /* rounded-lg */
  display: flex;
  align-items: center;
  flex-direction: column;

  .icon {
    padding: 0;
    margin-right: 0.5rem; /* mr-2 */
  }

  .alert-title {
    font-weight: bold;
    font-size: 1rem; /* text-lg */
  }

  .alert-description {
    font-size: 0.775rem; /* text-sm */
    margin-top: 0.25rem; /* mt-1 */
    display: flex;
    flex-direction: column;

    .accept-button {
      border: 1px solid #15803d; /* Success color */
      color: #334155; /* Slate-700 */
      margin-top: 0.25rem; /* mt-1 */
      border-radius: 0.5rem;
      padding: 0.25rem; /* py-1 px-2 */
      background-color: transparent;
      transition: background-color 0.2s;
      

      &:hover {
        background-color: #334155;
        color: white; /* Primary color for hover */
      }
    }
  }
}
</style>
