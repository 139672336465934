<template>
  <div class="container-overwrite">
    <div class="bg-title cd-display-container">
      <div class="cd-display-middle">
        <span class="cd-text-white cd-wide cd-cente">
          <h1 class="h1-title" data-aos="fade-right" data-aos-duration="500" data-aos-easing="ease-in-sine">Meet the
            team</h1>
        </span>
      </div>
    </div>
    <h2>Our Dentists</h2>
    <DentistCards />

    <h2>Our Nurses</h2>
    <div class="nurses">
      <div class="table" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
        <div class="row header">
          <div class="cell">Name</div>
          <div class="cell">GDC Registration</div>
          <div class="cell">Role</div>
        </div>

        <div class="subrow subheader">
          <div class="cell subcell" data-title="">Head Nurse</div>
          <div class="cell subcell" data-title=""></div>
          <div class="cell subcell" data-title=""></div>
        </div>

        <div class="row">
          <div class="cell" data-title="Nurse">Adele</div>
          <div class="cell" data-title="GDC Registration">261266</div>
          <div class="cell" data-title="Role">
            Joined the team in 2014, fully qualified in 2015.
          </div>
        </div>

        <div class="subrow subheader">
          <div class="cell subcell" data-title="">Dental Nurses</div>
          <div class="cell subcell" data-title=""></div>
          <div class="cell subcell" data-title=""></div>
        </div>

        <div class="row">
          <div class="cell" data-title="Nurse">Sara</div>
          <div class="cell" data-title="GDC Registration">297626</div>
          <div class="cell" data-title="Role">
            Dental Nurse, fully qualified - joined the team in 2021.
          </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Nurse">Kelly</div>
          <div class="cell" data-title="GDC Registration">304491</div>
          <div class="cell" data-title="Role">
            Dental Nurse, fully qualified in May 2011.
          </div>
        </div>

        <div class="subrow subheader">
          <div class="cell subcell" data-title="">Trainee Nurses</div>
          <div class="cell subcell" data-title=""></div>
          <div class="cell subcell" data-title=""></div>
        </div>
        <div class="row">
          <div class="cell" data-title="Nurse">Emma</div>
          <div class="cell" data-title="GDC Registration"></div>
          <div class="cell" data-title="Role">
            Joined us in 2021, and set to be qualified in 2023.
          </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Nurse">Victoria</div>
          <div class="cell" data-title="GDC Registration"></div>
          <div class="cell" data-title="Role">
            Joined us in August 2023, and set to be qualified in 2025.
          </div>
        </div>
      </div> <!-- end of table -->
    </div>
  </div>
</template>

<script>
import DentistCards from "../components/staff-cards/DentistCards";

export default {
  components: {
    DentistCards,
  },
};
</script>

<style lang="scss">
$color-accent-gold: #d5a433;


h2 {
  color: $color-accent-gold;
  padding: 1rem 2rem;
}
</style>

<style lang="sass" scoped>
$color-primary-dark: #4ecdc4 //#066e7a
$color-secondary-light: #066e7a //#4ecdc4
$color-accent-gold: #d5a433
$color-cloughoge: #c6006b

.nurses 
  padding: 10px 70pt 50px 70pt

h3
  padding-bottom: 10px
  color: $color-accent-gold

body
  font-size: 14px
  line-height: 20px
  font-weight: 400
  -webkit-font-smoothing: antialiased
  font-smoothing: antialiased

  @media screen and (max-width: 580px)
    font-size: 16px
    line-height: 22px

.wrapper
  margin: 0 auto
  padding: 40px
  max-width: 1200px

.table
  margin: 0 0 40px 0
  width: 80%
  display: table
  @media screen and (max-width: 580px)
    display: block

.subrow
    display: table-row-group
    background: #fff
    color: #545454
    text-align: center
    &.subheader
      font-weight: 400
      color: #fff
      background: $color-primary-dark

.row
  display: table-row
  background: #fff
  color: #545454

  &:nth-of-type(odd)
    background: #F8F9F9

  &.header
    font-weight: 900
    color: #fff
    background: $color-secondary-light

  &.green
    background: #27ae60

  &.blue
    background: #2980b9

  @media screen and (max-width: 580px)
    padding: 14px 0 7px
    display: block

    &.header
      padding: 0
      height: 6px
      .cell
        display: none

    .cell
      margin-bottom: 10px

      &:before
        margin-bottom: 3px
        content: attr(data-title)
        min-width: 98px
        font-size: 10px
        line-height: 10px
        font-weight: bold
        text-transform: uppercase
        color: $color-cloughoge //title color mobile
        display: block

.cell
  padding: 6px 12px
  display: table-cell
  border-bottom: 1px solid $color-accent-gold

  @media screen and (max-width: 580px)
    padding: 2px 16px
    display: block
    border-bottom: none

.subcell
  border-bottom: none
</style>