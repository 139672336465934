<template>
  <div>
    <div class="hero" id="heroTop">
      <div class="hero-content">
        <div class="hero-left">
          <div class="container">
            <h1>Professional dentistry that will make you smile</h1>
            <p class="hero-subtitle">
              Here at Cloughoge Dental, we are committed to providing high
              quality, personalised dental care in a comfortable environment
            </p>
            <button>
              <br />
              <div class="call">Call us on:</div>
              <a href="tel:02830266162">
                <div class="number"><b>NI:</b> 028 3026 6162</div>
              </a>
              or
              <a href="tel:04830266162">
                <br />
                <div class="number"><b>ROI:</b> 048 3026 6162</div>
              </a>
              <br />
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Container (About Section) -->
    <div class="cd-content cd-container cd-padding-64" id="about">
      <h3 class="cd-center" data-aos="fade-right" data-aos-duration="1500">
        Our Practice
      </h3>
      <div data-aos="fade-left" data-aos-duration="1500" class="cd-row">
        <div class="cd-col m6 cd-center cd-padding-large">
          <br />
          <img src="../assets/images/cloughoge/cd-front-vertical.jpeg" class="cd-round cd-image cd-hover-opacity-off"
            alt="CloughogeDP" width="350" height="200" />
        </div>

        <!-- Hide this text on small devices: cd-hide-small -->
        <div class="cd-col m6 cd-padding-large">
          <br />
          <p class="page-text">
            Here at Cloughoge Dental, we pride ourselves on our ability to
            provide high quality dentistry in a comfortable environment.

          </p>
          <p class="page-text">
            Collectively we have over 80 years experience with General, Private,
            Paediatric and Orthodontic treatments.
          </p>
          <p class="page-text">
            Together with our team of highly trained Dentists and Nurses our aim
            is to make your visit as pleasant as possible and ensure you achieve
            the smile you deserve.
          </p>
        </div>
      </div>
    </div>

    <div class="cd-row cd-center cd-dark-teal cd-padding-16">
      <div data-aos="flip-up" data-aos-duration="1500" class="cd-quarter cd-section">
        <span class="cd-xlarge">10+</span><br />
        Qualified Dentists <br />
        & Nurses
      </div>
      <div data-aos="flip-down" data-aos-duration="2000" class="cd-quarter cd-section">
        <span class="cd-xlarge">80+</span><br />
        Years <br />
        Experience
      </div>
      <div data-aos="flip-up" data-aos-duration="2500" class="cd-quarter cd-section">
        <span class="cd-xlarge">10,000+</span><br />
        Smiling <br />
        Customers
      </div>
      <div data-aos="flip-down" data-aos-duration="3000" class="cd-quarter cd-section">
        <span class="cd-xlarge">2010</span><br />
        Investors in People <br />
        Award Winner
      </div>
    </div>

    <div class="bgimg-2 cd-display-container">
      <div class="cd-display-middle">
        <!-- <span class="cd-xxlarge cd-text-white cd-wide"><h3 class="cd-center">WHAT WE OFFER</h3></span> -->
      </div>
    </div>

    <div class="cd-content cd-container cd-padding-64">
      <h3 data-aos="fade-left" data-aos-duration="2000" class="cd-center">
        What We Offer
      </h3>
      <p class="cd-center">
        <em>With years of experience, we'll have everything you need!</em>
      </p>
      <div data-aos="fade-right" data-aos-duration="2000">
        <br />
        <section class="imageCollage">
          <div class="imageCollage_text">
            <h4>Find the right treatment for you</h4>
          </div>
          <img class="imgOne" src="../assets/images/brushing-teenager-girl.jpg" alt="brushing teeth">
          <img src="../assets/images/surgery/surgery-older-man.jpeg" alt="smiling older man">
          <img src="../assets/images/surgery/surgery-tools-spray.jpg" alt="dental utensils">
          <img src="../assets/images/pain-tooth-kid.jpg" alt="childrens health">
          <img src="../assets/images/surgery/surgery-woman-smile-blonde.jpg" alt="Placeholder">
        </section>
        <p class="cd-center page-text">
          With our experienced Dentists and Nursing staff combined with our modern facilities, we can offer you
          the best treatment and recovery plans no matter the situation.
        </p>
        <p class="cd-center page-text">
          Take a look at our <router-link class="link" :to="{ name: 'Treatments' }" @click="toggleMobileNav">Treatments Page</router-link> to see the wide variety of treatments we provide or email us with any
          questions you might have and we'll be happy to help!
        </p>
      </div>
    </div>

    <!-- Modal for full size images on click-->
    <div id="modal01" class="cd-modal cd-black" onclick="this.style.display='none'">
      <span class="cd-button cd-large cd-black cd-display-topright" title="Close Modal Image"><i
          class="fa fa-remove"></i></span>
      <div class="
          cd-modal-content
          cd-animate-zoom
          cd-center
          cd-transparent
          cd-padding-64
        ">
        <img id="img01" class="cd-image" />
        <p id="caption" class="cd-opacity cd-large"></p>
      </div>
    </div>

    <div class="bgimg-3 cd-display-container">
      <div class="cd-display-middle">
        <span class="cd-xxlarge cd-text-white cd-wide"></span>
      </div>
    </div>

    <!-- Container (Contact Section) -->
    <div class="cd-content cd-container cd-padding-64" id="contact">
      <div data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
        <h3 class="cd-center">Get In Touch</h3>
        <p class="cd-center"><em>We'd love to hear from you!</em></p>
      </div>

      <div class="grid" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
        <div class="form-left">
          <ContactForm />
        </div>
        <div class="form-right">
          <ContactDetails />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactForm from "../components/contact/ContactForm";
import ContactDetails from "../components/contact/ContactDetails.vue";
export default {
  name: "Home",
  components: { ContactForm, ContactDetails },
};
</script>


<style>
/* Create a Parallax Effect */
.bgimg-2,
.bgimg-3 {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bgimg-2 {
  background-image: linear-gradient(to bottom,
      rgba(0, 0, 2, 0.52),
      rgba(78, 78, 78, 0.041)),
    url("../assets/images/products/dental_products_4.jpeg");
  min-height: 400px;
}

.bgimg-3 {
  background-image: linear-gradient(to bottom,
      rgba(0, 0, 2, 0.52),
      rgba(78, 78, 78, 0.041)),
    url("../assets/hero/group-smile.jpg");
  min-height: 400px;
}

.cd-wide {
  letter-spacing: 10px;
}

.cd-hover-opacity {
  cursor: pointer;
}

/* Turn off parallax scrolling for tablets and phones */
@media only screen and (max-device-width: 1600px) {

  .bgimg-2,
  .bgimg-3 {
    background-attachment: scroll;
    min-height: 400px;
  }
}
</style>

<style lang="scss" scoped>
@import "../css/import.css";
@import "../css/hero.scss";

// section-titles
h3 {
  color: $phone-color;
  padding-bottom: 1em;
  font-size: 2.5rem;
  font-weight: 800;
  text-decoration: underline;
  text-decoration-color: #066e7a;
}

h4 {
  color: #fff;
  padding-bottom: 1em;
  font-size: 2.5rem;
  text-decoration: underline;
  text-decoration-color: #d5a433;
}


// text
.page-text {
  font-weight: lighter;
  color: #2a3845;
  font-size: 1.125rem;
  line-height: 1.5em;
  padding: 1rem 0;
}

// Hero
h1 {
  font-family: "Sora", sans-serif;
  margin: 0;
  color: $text-color;
  font-size: 3.5rem;

  //reduce the size of the text for smaller screens
  @media (max-width: 450px) {
    font-size: 2.5rem;
  }
}

// Contact Section
.grid {
  display: block;
  padding: 1em;
}

.form-left {
  grid-column: span 7;
  padding: 2em;
}

.form-right {
  grid-column: span 5;
  padding: 2em;
}

@media screen and (min-width: 927px) {
  .grid {
    display: grid;
    // grid-template-rows: repeat(6, 1fr);
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 10px;
  }
}


.imageCollage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 900px;
}

.imageCollage_text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #066e7a;
  border-radius: 3px;
  font-size: 1.5rem;
  width: 300px;
  margin: 2px;
}

.imageCollage img {
  height: 200px;
  flex-grow: 1;
  object-fit: cover;
  margin: 2px;
}

.imageCollage .imgOne {
  max-height: 300px;
  height: auto;
  flex-grow: 1;
  object-fit: cover;
  margin: 2px;
}

@media (max-width:875px) {
  .imageCollage .imgOne {
    height: 100px;
    flex-grow: 1;
    object-fit: cover;
    margin: 2px;
  }

  .imageCollage img {
    height: 100px;
    flex-grow: 1;
    object-fit: cover;
    margin: 2px;
  }

  .imageCollage_text {
    padding: 20px;
    background: #066e7a;
    text-align: center;
    border-radius: 3px;
    width: 100%;
    margin: 2px;
  }

  h4 {
    color: #fff;
    padding-bottom: 1em;
    font-size: 1.5rem;
    font-weight: 800;
    text-decoration: underline;
    text-decoration-color: #d5a433;
  }
}
</style>