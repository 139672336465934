<template>
  <div class="container-overwrite">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2458.326282706373!2d-6.360719567877563!3d54.141718411548986!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x506f1be300ec6f9c!2sCloughoge%20Dental%20Practice!5e0!3m2!1sen!2suk!4v1641502095999!5m2!1sen!2suk"
      class="maps"
      loading="lazy"
    ></iframe>
    <div class="grid" data-aos="fade-up" data-aos-duration="1500">
      <div class="form-left">
        <h2>Contact Us</h2>
        <ContactForm />
      </div>

      <div class="form-right">
        <ContactDetails />

        <div class="social-media">

          <a href="mailto:info@cloughogedental.com" target="_blank" title="Email Us">
            <i class="fa-solid fa-envelope fa-3x color-gold"> </i>
          </a>

          <a
              href="https://www.google.com/maps/place/Cloughoge+Dental+Practice/@54.1415417,-6.3603262,15z/data=!4m5!3m4!1s0x0:0x506f1be300ec6f9c!8m2!3d54.1415379!4d-6.3602592"
              target="_blank" title="Google Us">
            <i class="fa-brands fa-google fa-3x color-gold"> </i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactForm from "../components/contact/ContactForm";
import ContactDetails from "../components/contact/ContactDetails";

export default {
  components: {
    ContactForm,
    ContactDetails,
  },
};
</script>

<style lang="scss" scoped>
$color-cloughoge: #c6006b;

.maps {
  min-width: 100%;
  height: 400px;
  margin: 0 auto;
  border: 0;
}

.container-overwrite {
  padding-top: 6.5em;
}

.grid {
  display: block;
  padding: 1em;
}

.form-left {
  grid-column: span 7;
  padding: 2em;
}

.form-right {
  grid-column: span 5;
  padding: 2em;
}

h2 {
  color: $color-cloughoge;
  padding-top: 0.125rem;
  padding-bottom: 0.5rem;
}

@media screen and (min-width: 927px) {
  .grid {
    display: grid;
    // grid-template-rows: repeat(6, 1fr);
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 10px;
  }
}

.social-media {
  display: flex;
  height: auto;
  width: 64px;
  padding-top: 2rem;
  padding-right: 1rem;
  i {
    padding: 0 10px;
  }
}
</style>