<template>
  <div class="container-overwrite">
    <div class="bg-title cd-display-container">
      <div class="cd-display-middle">
        <span class="cd-text-white cd-wide cd-center">
          <h1 class="h1-title" data-aos="fade-right" data-aos-duration="500" data-aos-easing="ease-in-sine">Frequently
            Asked Questions</h1>
        </span>
      </div>
    </div>
    <div class="content">
      <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1000">
        <h2>Appointments</h2>
        <app-accordion>
          <template v-slot:title>
            <div class="span">
              How do I book an appointment?
            </div>
          </template>
          <template v-slot:content>
            <p>
              You can call into the practice or phone us on 302 66162 and we will be happy to help. We will send
              a reminder text the day before your appointment. Please ensure all contact details are up to date.
            </p>
          </template>
        </app-accordion>

        <app-accordion>
          <template v-slot:title>
            <div class="span">
              If I miss my appointment, will there be a charge?
            </div>
          </template>
          <template v-slot:content>
            <p>
              A fee will be applied for short notice appointments or failed to attend appointments.
              <br />
              <br />
              Please be aware, if two appointments are cancelled on short notice or not attended (without good
              reason) no more appointments will be offered at the practice.
            </p>
          </template>
        </app-accordion>

        <app-accordion>
          <template v-slot:title>
            <div class="span">Can I get an emergency appointment?</div>
          </template>
          <template v-slot:content>
            <p>
              Yes we have pain slots held with each of our dentists every day.
              <br />
              <br />
              We try our best to accommodate anyone having pain however, we would advise patients to phone first thing in
              the morning as appointments are subject to availability.
            </p>
          </template>
        </app-accordion>
      </div>

      <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2000">
        <br/>
        <h2>Services</h2>
        <app-accordion>
          <template v-slot:title>
            <div class="span">
              I am really nervous about going to the dentist, can you help me?
            </div>
          </template>
          <template v-slot:content>
            <p>
              We deal with nervous patients on a regular basis. The best thing we can advise is to book your
              appointment and let us know at that stage you are nervous. We will take our time and listen to your
              concerns.
              <br />
              <br />
              When you come for the appointment, we will take it step by step and only do as much as you are
              ready for. You will always be in control and we can stop at any stage to give you a break. We use a
              numbing cream to help before any injections are given. Some of our patients take in their head
              phone/ear phones to distract them during surgery.
              <br />
              <br />
              If you haven’t been for a dental check in a long time, you may be concerned with the amount of
              treatment needed. Try not to panic, it’s not as bad as you imagine. The most important part is
              making the phone call.
            </p>
          </template>
        </app-accordion>

        <app-accordion>
          <template v-slot:title>
            <div class="span">I would like composite bonding done, can you do this?</div>
          </template>
          <template v-slot:content>
            <p>
              Composite bonding is a specialised treatment and not something we would provide in the practice.
            </p>
          </template>
        </app-accordion>

        <app-accordion>
          <template v-slot:title>
            <div class="span">I am on Universal Credits, am I entitled to free dental treatment?</div>
          </template>
          <template v-slot:content>
            <p>
              Universal Credit does not automatically mean you are eligible for free treatment. We have HC1
              forms in the practice we can give you to apply for help with dental costs.
              <br />
              <br />

              For further information on all types of exemptions please go to:
              <a href="https://www.nhs.uk/nhs-services/dentists/dental-costs/get-help-with-dental-costs/">NHS Services</a>
            </p>
          </template>
        </app-accordion>

        <app-accordion>
          <template v-slot:title>
            <div class="span">Am I suitable for teeth whitening?</div>
          </template>
          <template v-slot:content>
            <p>
              We would advise a consultation appointment before teeth whitening can be carried out. We would
              not recommend teeth whitening for anyone with sensitive teeth as the whitening process can
              heighten sensitivity.
              <br />
              <br />
              Teeth whitening will only work on natural teeth; it will not alter the colour of
              crowns or fillings you may have. Your dentist will be able to discuss options with you at the
              consultation.
            </p>
          </template>
        </app-accordion>
      </div>

      <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2000">
        <br/>
        <h2>Our Practice</h2>
        <app-accordion>
          <template v-slot:title>
            <div class="span">Do you have a hygienist?</div>
          </template>
          <template v-slot:content>
            <p>
              We do not have a hygienist in the practice, our dentists would carry out a scale (cleaning of the
              teeth) themselves. If you require periodontal treatment, we can refer you to a specialist to have this
              carried out.
            </p>
          </template>
        </app-accordion>
      </div>
    </div>
  </div>
</template>

<script>
import AppAccordion from "../components/AppAccordion";

export default {
  components: {
    AppAccordion,
  },
};
</script>

<style scoped>
.content {
  max-width: 80%;
  margin: 3em 1em;
  padding-left: 1.125rem;
}

.span {
  width: 90%;
}

p {
  font-size: 1rem;
  font-weight: 300;
  color: #000;
}
</style>