<template>
  <div class="container-overwrite">
    <div class="bg-title cd-display-container">
      <div class="cd-display-middle">
        <span class="cd-text-white cd-wide cd-cente">
          <h1 class="h1-title" data-aos="fade-right" data-aos-duration="500" data-aos-easing="ease-in-sine">Careers</h1>
        </span>
      </div>
    </div>
  </div>

      <div class="cd-content cd-container cd-padding-32" id="about">
      <h2 class="cd-center header-top" data-aos="fade-right" data-aos-duration="1500">
        Would you like to join our team?
      </h2>
      <br/>

      <div data-aos="fade-left" data-aos-duration="1500" class="cd-row">
        <div class="cd-col m5 cd-center cd-padding-small">
          <br />
          <img src="../assets/images/cloughoge/treatment-chair-vertical.png" class="cd-round cd-image cd-hover-opacity-off"
            alt="CloughogeDP" width="350" height="400" />
        </div>

        <div class="cd-col m6">
          <br />
          <h3 class="page-text">Associate Dentist</h3>

          <h4 class="page-text">Job Description</h4>
          <div class="page-text">
            <p>• Full / part time with flexible hours</p>
            <p>• Private/NHS</p>
            <p>• Patient list available</p>
            <p>• Excellent support staff</p>
            <p>• Great earning potential</p>
            <p>• Must be GDC registered with proof of indemnity</p>
          </div>
          <br />
          
          <h4 class="page-text">About the Practice</h4>
          <div class="page-text">
            <p>• Independently owned, modern purpose built 4 surgery practice with room for expansion</p>
            <p>• Excellent location outside of Newry, just off A1 so avoids traffic</p> 
            <p>• Ample free parking outside</p> 
            <p>• Mixed Private / NHS</p> 
            <p>• Excellent location for cross border trade</p> 
            <p>• Recently updated, fully computerised setup</p> 
            <p>• All surgeries have digital x rays, rotary endo, and apex locators</p> 
            <p>• Longstanding associates in position (both former foundation dentists who chose to stay with us)</p>
          </div>

        <br/>
          <div><strong>Download Job Description: </strong><a href="/Documents/Associate_Dentist.pdf" target="_blank" rel="noopener noreferrer" download>Associate Dentist</a></div>
          <div><strong>Email: </strong><a href="mailto:adele.campbell@cloughogedental.com" target="_blank">Adele Campbell</a></div>
        </div>
      </div>

      <hr>

      <div data-aos="fade-left" data-aos-duration="1500" class="cd-row">
        <div class="cd-col m5 cd-center cd-padding-small">
          <br />
          <img src="../assets/images/cloughoge/cd-front-vertical.jpeg" class="cd-round cd-image cd-hover-opacity-off"
            alt="CloughogeDP" width="300" height="125" />
        </div>

        <div class="cd-col m6">
          <br />
          <h3 class="page-text">Dental Nurse</h3>

          <h4 class="page-text">Job Description</h4>
          <div class="page-text">
            <p>• Providing clinical chair-side support to dentists</p>
            <p>• Updating patient records</p>
            <p>• Decontamination duties</p>
            <p>• Ensuring RQIA requirements are met</p>
            <p>• Providing excellent patient care</p>
          </div>
          <br />
          
          <h4 class="page-text">What we offer</h4>
          <div class="page-text">
            <p>• GDC Registration fee paid</p>
            <p>• Indemnity cover provided</p> 
            <p>• Free CPD</p> 
            <p>• Koi uniforms provided</p> 
            <p>• Friendly, supportive team and management</p> 
            <p>• Annual performance reviews and appraisals</p> 
            <p>• Pension scheme</p> 
            <p>• Free parking</p>
          </div>

        <br/>
          <div><strong>Download Job Description: </strong><a href="/Documents/Dental_Nurse.pdf" target="_blank" rel="noopener noreferrer" download>Dental Nurse</a></div>
          <div><strong>Email: </strong><a href="mailto:adele.campbell@cloughogedental.com" target="_blank">Adele Campbell</a></div>
        </div>
      </div>

    </div>
</template>


<style lang="scss">
$color-accent-gold: #d5a433;
$color-cloughoge: #c6006b;

h2 {
  color: $color-accent-gold;
  padding: 0.25rem;
}

h3 {
  color: $color-cloughoge;
  margin-bottom: 0.75rem;
}

h4 {
  text-decoration: underline
}

.page-text > p {
  padding-left: 0.5rem;
  line-height: 1.75rem
}


@media only screen and (max-width: 600px) {
.page-text {
  padding-left: 2rem;
}

}
</style>

<style lang="sass" scoped>
$color-primary-dark: #4ecdc4 //#066e7a
$color-secondary-light: #066e7a //#4ecdc4
$color-accent-gold: #d5a433
$color-cloughoge: #c6006b

.header-top
  margin-bottom: 0.5rem
  color: $color-accent-gold

body
  font-size: 14px
  line-height: 20px
  font-weight: 400
  -webkit-font-smoothing: antialiased
  font-smoothing: antialiased

  @media screen and (max-width: 580px)
    font-size: 16px
    line-height: 22px
</style>