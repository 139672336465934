<template>
  <table>
    <tr>
      <td class="table-header">Opening Hours</td>
    </tr>
    <tr class="content">
      <td class="day">Monday</td>
      <td class="hours">9:00am - 8:30pm</td>
    </tr>
    <tr class="content">
      <td class="day">Tuesday</td>
      <td class="hours">9:00am - 5:00pm</td>
    </tr>
    <tr class="content">
      <td class="day">Wednesday</td>
      <td class="hours">9:00am - 5:00pm</td>
    </tr>
    <tr class="content">
      <td class="day">Thursday</td>
      <td class="hours">8:15am - 5:00pm</td>
    </tr>
    <tr class="content">
      <td class="day">Friday</td>
      <td class="hours">9:00am - 1:00pm</td>
    </tr>
  </table>
</template>

<style lang="scss" scoped >
$color-accent-gold: #d5a433b2;
$color-accent-gold-text: #d5a433;
$color-font: rgb(255, 255, 255);

table {
  border-collapse: collapse;
}
.table-header {
  font-size: 1.4rem;
  text-align: left;
  color: $color-accent-gold-text;
}

.content {
  height: 40px;
  vertical-align: bottom;
  color: $color-font;
  border-bottom: 1px solid $color-accent-gold;
}
.day {
  text-align: left;
  width: 250px;
}
.hours {
  text-align: right;
  width: 150px;
}
</style>

<script>
export default {
  name: "OpeningHours",
};
</script>