<template>
  <div class="contact-information">
    <i class="fa fa-map-marker cd-xlarge cd-margin-right"></i>
    <h4>&nbsp;Address</h4>
    <p class="paragraph">51C Forkhill Road</p>
    <p class="paragraph">Cloughoge, Newry</p>
    <p>BT35 8QX</p>

    <br />
    <i class="fa fa-phone cd-xlarge cd-margin-right"></i>
    <h4>Phone Number</h4>
    <p>NI: &emsp; 028 3026 6162</p>
    <p>ROI: &ensp; 048 3026 6162</p>
    <br />

    <i class="fa fa-envelope cd-xlarge cd-margin-right"></i>
    <h4>Email</h4>
    <p>
      <a href="mailto:info@cloughogedental.com"
        >info@cloughogedental.com</a
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "ContactDetails",
};
</script>

<style lang="scss">
$color-primary-dark: #066e7a;
$color-secondary-light: #4ecdc4;
$color-accent-gold: #d5a433;
$color-cloughoge: #c6006b;

.color-cloughoge {
  color: $color-cloughoge;
}

.contact-information {
  display: inline-block;
  color: $color-cloughoge;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  h4 {
    display: inherit;
    padding-bottom: 5px;
  }
}

p {
  color: rgb(82, 82, 82);
}

a {
  color: $color-secondary-light;
  text-decoration: none;
  &:visited {
    color: $color-secondary-light;
    text-decoration: none;
  }
  &:hover {
    color: $color-cloughoge;
    text-decoration: underline;
  }
}
</style>