<template>
  <footer class="footer cd-center cd-padding-16">
    <div class="social-media">

      <a href="mailto:info@cloughogedental.com" target="_blank" title="Email Us">
        <i class="fa-solid fa-envelope fa-3x color-gold"> </i>
      </a>

      <a
          href="https://www.google.com/maps/place/Cloughoge+Dental+Practice/@54.1415417,-6.3603262,15z/data=!4m5!3m4!1s0x0:0x506f1be300ec6f9c!8m2!3d54.1415379!4d-6.3602592"
          target="_blank" title="Google Us">
        <i class="fa-brands fa-google fa-3x color-gold"> </i>
      </a>
    </div>

    <div class="grid">
      <div class="form-left">
        <OpeningHours />
      </div>

      <div class="form-right">
        <div class="contact-information">
          <i class="fa fa-map-marker cd-xlarge cd-margin-right"></i>
          <h4>&nbsp;Address</h4>
          <p class="paragraph">51C Forkhill Road</p>
          <p class="paragraph">Cloughoge, Newry</p>
          <p>BT35 8QX</p>

          <br />
          <i class="fa fa-phone cd-xlarge cd-margin-right"></i>
          <h4>Phone Number</h4>
          <p>NI: &emsp; 028 3026 6162</p>
          <p>ROI: &ensp; 048 3026 6162</p>
          <br />

          <i class="fa fa-envelope cd-xlarge cd-margin-right"></i>
          <h4>Email</h4>
          <p>
            <a href="mailto:info@cloughogedental.com">info@cloughogedental.com</a>
          </p>
        </div>
      </div>
    </div>
      <a href="https://www.brendancampbell.dev" target="_blank">Site Design by Brendan Campbell</a>
  </footer>
</template>

<script>
import OpeningHours from "../components/OpeningHours";

export default {
  components: {
    OpeningHours,
  },
};
</script>


<style scoped lang="scss">
$color-primary-dark: #066e7a;
$color-secondary-light: #4ecdc4;
$color-accent-gold: #d5a433b2;
$color-accent-gold-text: #d5a433;
$color-cloughoge: #c6006b;
$color-font: rgb(255, 255, 255);

@media screen and (min-width: 927px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
}

.footer {
  background-color: $color-primary-dark;
}

.grid {
  padding: 0 1em;
}

a:hover {
  color: $color-accent-gold;
  text-decoration: none;
}

.social-media {
  a {
    i {
      padding: 0 10px;
      color: $color-secondary-light;
      text-decoration: none;

      &:visited {
        color: $color-secondary-light;
        text-decoration: none;
      }

      &:hover {
        color: $color-accent-gold;
        text-decoration: none;
      }
    }
  }
}

.form-left {
  grid-column: span 7;
  padding: 2em;
}

.form-right {
  grid-column: span 5;
  padding: 2em;

  p {
    color: white;
  }
}

.color-gold {
  color: $color-accent-gold-text;
}

// overwrite parent component styling
.contact-information {
  display: inline-block;
  color: $color-accent-gold-text;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  h4 {
    padding-bottom: 5px;
    color: $color-accent-gold-text;
  }
}
</style>