<template>
  <div class="accordion">
    <div class="accordion-item">
      <button
        @click="toggleAccordion()"
        :aria-expanded="isOpen"
        :aria-controls="`collapse${_uid}`"
      >
        <slot name="title" />
        <div class="icon"></div>
      </button>

      <div v-show="isOpen" :id="`collapse${_uid}`" class="accordion-content">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped lang="scss">
$color-primary-dark: #066e7a;
$color-secondary-light: #4ecdc4;
$color-accent-gold: #d5a433;

.accordion {
  .accordion-item {
    padding: 1em 0;
    border-bottom: 1px solid $color-accent-gold;
    button[aria-expanded="true"] {
      border-bottom: 1px solid $color-secondary-light;
    }
  }
  button {
    position: relative;
    display: block;
    text-align: left;
    width: 100%;
    padding: 1em 0;
    color: $color-primary-dark;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    background: none;
    outline: none;
    &:hover,
    &:focus {
      cursor: pointer;
      background: rgba(194, 194, 194, 0.23);
      color: $color-secondary-light;
      &::after {
        cursor: pointer;
        color: $color-secondary-light;
        border: 1px solid $color-secondary-light;
        padding: 0.3em 0;
      }
    }
    .accordion-title {
      padding: 1em 1.5em 1em 0;
    }
    .icon {
      display: inline-block;
      position: absolute;
      top: 18px;
      right: 0;
      width: 22px;
      height: 22px;
      border: 1px solid;
      border-radius: 22px;
      &::before {
        display: block;
        position: absolute;
        content: "";
        top: 9px;
        left: 5px;
        width: 10px;
        height: 2px;
        background: currentColor;
      }
      &::after {
        display: block;
        position: absolute;
        content: "";
        top: 5px;
        left: 9px;
        width: 2px;
        height: 10px;
        background: currentColor;
      }
    }
  }
  button[aria-expanded="true"] {
    color: $color-secondary-light;
    .icon {
      &::after {
        width: 0;
      }
    }
    + .accordion-content {
      opacity: 1;
      transition: all 200ms linear;
      will-change: opacity, max-height;
      width: 90%;
    }
  }
  .accordion-content {
    margin: 0.5em 1em;
    opacity: 0;
    overflow: hidden;
    transition: opacity 200ms linear, max-height 200ms linear;
    will-change: opacity, max-height;
  }
}
</style>