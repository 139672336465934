<template>
  <header :class="{ 'scrolled-nav': scrolledNav }">
    <nav>
      <!-- Desktop Navigation -->
      <div class="branding">
        <router-link @click="closeMobileNav" :to="{ name: 'Home' }">
          <img src="@/assets/logos/logo_and_text_side.svg" alt="cloughoge dental practice" />
        </router-link>
      </div>
      <ul v-show="!mobile" class="navigation">
        <li>
          <router-link class="link" :to="{ name: 'Home' }">Home</router-link>
        </li>
        <li>
          <router-link class="link" :to="{ name: 'Treatments' }">Treatments</router-link>
        </li>
        <li>
          <router-link class="link" :to="{ name: 'OurTeam' }">Our Team</router-link>
        </li>
        <li>
          <router-link class="link" :to="{ name: 'FAQ' }">FAQ</router-link>
        </li>
        <li>
          <router-link class="link" :to="{ name: 'ContactUs' }">Contact Us</router-link>
        </li>
        <li>
          <router-link class="link" :to="{ name: 'Careers' }">Careers</router-link>
        </li>
      </ul>

      <!-- Mobile Navigation -->
      <div class="icon">
        <i @click="toggleMobileNav" v-show="mobile" class="fa fa-bars" :class="{ 'icon-active': mobileNav }"></i>
      </div>

      <transition name="mobile-nav">
        <ul v-show="mobileNav" class="dropdown-nav">
          <li>
            <router-link class="link" :to="{ name: 'Home' }" @click="toggleMobileNav">Home</router-link>
          </li>
          <li>
            <router-link class="link" :to="{ name: 'Treatments' }" @click="toggleMobileNav">Treatments</router-link>
          </li>
          <li>
            <router-link class="link" :to="{ name: 'OurTeam' }" @click="toggleMobileNav">Our Team</router-link>
          </li>
          <li>
            <router-link class="link" :to="{ name: 'FAQ' }" @click="toggleMobileNav">FAQ</router-link>
          </li>
          <li>
            <router-link class="link" :to="{ name: 'ContactUs' }" @click="toggleMobileNav">Contact Us</router-link>
          </li>
          <li>
            <router-link class="link" :to="{ name: 'Careers' }" @click="toggleMobileNav">Careers</router-link>
          </li>
          <li class="contact-details">
            <p>51C Forkhill Road</p>
            <p>Cloughoge, Newry</p>
            <p>028 3026 6162</p>
          </li>
        </ul>
      </transition>
    </nav>
  </header>
</template>

<script>
export default {
  name: "navigation",
  data() {
    return {
      scrolledNav: null,
      mobile: null,
      mobileNav: null,
      windowWidth: null,
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  watch: {
    $route: function () {
      //Only show transparent bg on navbar on homepage
      if (this.$route.path === "/Home" || this.$route.path === "/") {
        this.scrolledNav = false;
      } else {
        this.scrolledNav = true;
      }
    },
  },
  methods: {
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },

    closeMobileNav() {
      this.Mobilenav = false;
    },

    updateScroll() {
      const scrollPosition = window.scrollY;

      if (this.$route.path === "/Home" || this.$route.path === "/") {
        if (scrollPosition > 50) {
          this.scrolledNav = true;
          return;
        }
        this.scrolledNav = false;
      }
    },

    checkScreen() {
      this.windowWidth = window.innerWidth;

      if (this.windowWidth <= 926) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
};
</script>

<style lang="scss" scoped>
$color-secondary-light: #4ecdc4;
$color-accent-gold: #d5a433;

header {
  background-color: rgba(15, 15, 15, 0);
  z-index: 99;
  width: 100%;
  position: fixed;
  transition: 0.5s ease all;
  color: #fff;

  nav {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 12px 0;
    transition: 0.5s ease all;
    width: 85%;
    margin: 0 150px 0 40px;

    @media (min-width: 1140px) {
      max-width: 80%;
    }

    ul,
    .link {
      font-weight: 500;
      color: #fff;
      list-style: none;
      text-decoration: none;
    }

    li {
      text-transform: uppercase;
      padding: 16px;
      margin-left: 16px;
    }

    li:last-child {
      margin-right: 10px;
    }

    .link {
      transition: 0.5s ease all;
      padding-bottom: 4px;
      border-bottom: 1px solid transparent;

      &:hover {
        color: $color-secondary-light;
        border-color: $color-secondary-light;
      }
    }

    .branding {
      display: flex;
      align-items: center;

      img {
        width: 13rem;
        transition: 0.5s ease all;
      }
    }

    .navigation {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;
    }

    .icon {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 15%;
      height: 100%;

      i {
        cursor: pointer;
        font-size: 48px;
        transition: 0.5s ease all;
        color: $color-accent-gold;
        padding: 12px;
      }
    }

    .icon-active {
      transform: rotate(180deg);
    }

    .dropdown-nav {
      display: flex;
      flex-direction: column;
      position: fixed;
      width: 100%;
      max-width: 100%;
      height: 100%;
      background-color: rgb(245, 245, 245);
      top: 0;
      left: 0;
      padding-top: 15px;

      li {
        margin-top: 2rem;

        .link {
          color: $color-accent-gold;
          font-weight: bold;
          padding-left: 30%;
          font-size: larger;

          &:hover {
            color: $color-secondary-light;
            border-color: $color-secondary-light;
          }
        }
      }

      .contact-details {
        display: inherit;
        flex-direction: column;
        padding-left: 30%;
        padding-top: 10%;

        p {
          font-size: 0.8rem;
          font-style: italic;
          color: rgb(167, 167, 167);
          line-height: 1.25rem;
          padding-top: 5px;
        }
      }
    }

    // opening and closing the nav bar
    .mobile-nav-enter-active,
    .mobile-nav-leave-active {
      transition: 0.8s ease all;
    }

    .mobile-nav-enter-from,
    .mobile-nav-leave-to {
      transform: translateX(-100%);
    }

    .mobile-nav-enter-to {
      transform: translateX(0);
    }
  }
}

.scrolled-nav {
  background-color: rgba(0, 0, 0, 0.881);

  nav {
    padding: 8px 0;
  }
}
</style>