import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Treatments from '../views/Treatments.vue'
import OurTeam from '../views/OurTeam.vue'
import FAQ from '../views/FAQ.vue'
import ContactUs from '../views/ContactUs.vue'
import Careers from '../views/Careers.vue'
import PathNotFound from '../views/Error.vue'

const routes = [
  {
    path: '',
    component: Home
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/Treatments',
    name: 'Treatments',
    component: Treatments
  },
  {
    path: '/OurTeam',
    name: 'OurTeam',
    component: OurTeam
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/Careers',
    name: 'Careers',
    component: Careers
  },
  {
    path: '/:pathMatch(.*)*',
    component: PathNotFound
  },

]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router