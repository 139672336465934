<template>
  <ul>
    <!-- David Section -->
    <li class="booking-card" id="David" data-aos="flip-left" data-aos-duration="1000">
      <div class="book-container">
<!--        <div class="content">-->
<!--          <button class="btn">Contact</button>-->
<!--        </div>-->
      </div>
      <div class="informations-container">
        <h2 class="title">Dr David O'Neill</h2>
        <p class="sub-title">Practice Owner &#38; Principal Dentist</p>
        <p class="registration">GDC registration: 71989</p>
        <div class="more-information">
          <!-- <div class="info-and-date-container">
            <div class="box date">
              <svg class="icon" style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path fill="currentColor"
                  d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z" />
              </svg>
              <p>Availability:</p>
              <br />
              <p>Monday, <s>Tuesday</s>, Wednesday, Thursday & Friday</p>
            </div>
          </div> -->
          <p class="disclaimer">
            David is the joint practice owner and principal, qualified in 1996 from Queens University Belfast. He
            started his career in dentistry working in Belfast.
            <br />
            <br />
            David set up Cloughoge Dental Practice in 2004 with Russell Smith and is particularly proud of the wide
            range of up-to-date treatments offered by the practice.
          </p>
        </div>
      </div>
    </li>

    <!-- Russell Section -->
    <li class="booking-card" id="Russell" data-aos="flip-left" data-aos-duration="1500">
      <div class="book-container">
<!--        <div class="content">-->
<!--          <button class="btn">Contact</button>-->
<!--        </div>-->
      </div>
      <div class="informations-container">
        <h2 class="title">Dr Russell Smith</h2>
        <p class="sub-title">Practice Owner &#38; Principal Dentist</p>
        <p class="registration">GDC registration: 70868</p>
        <div class="more-information">
          <!-- <div class="info-and-date-container">
            <div class="box date">
              <svg class="icon" style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path fill="currentColor"
                  d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z" />
              </svg>
              <p>Availability:</p>
              <br />
              <p>Monday, Tuesday, <s>Wednesday</s>, Thursday & Friday</p>
            </div>
          </div> -->
          <p class="disclaimer">
            Russell is the joint practice owner and principal, qualified in 1995 from Dundee University and spent five
            years working in both NHS and Private practice in Scotland before returning to Ireland in 2001.
            <br />
            <br />
            Together with David he opened Cloughoge Dental Practice in 2004 to offer quality family dental care in a
            modern and relaxed setting.
          </p>
        </div>
      </div>
    </li>

    <!-- Diane Section -->
    <li class="booking-card" id="Diane" data-aos="flip-left" data-aos-duration="2000">
      <div class="book-container">
<!--        <div class="content">-->
<!--          <button class="btn">Contact</button>-->
<!--        </div>-->
      </div>
      <div class="informations-container">
        <h2 class="title">Dr Diane McWhirter</h2>
        <p class="sub-title">Dentist</p>
        <p class="registration">GDC registration: 113343</p>
        <div class="more-information">
          <!-- <div class="info-and-date-container">
            <div class="box date">
              <svg class="icon" style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path fill="currentColor"
                  d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z" />
              </svg>
              <p>Availability:</p>
              <br />
              <p>Monday, Tuesday, Wednesday, <s>Thursday</s> & Friday</p>
            </div>
          </div> -->
          <p class="disclaimer">
            Qualified in 2007, Diane takes a keen interest in paediatric
            dentistry &#38; anxious patients, providing general family care
            &#38; helping patients achieve optimal oral health.
          </p>
        </div>
      </div>
    </li>

    <!-- Rebecca Section -->
    <li class="booking-card" id="Rebecca" data-aos="flip-left" data-aos-duration="2500">
      <div class="book-container">
<!--        <div class="content">-->
<!--          <button class="btn">Contact</button>-->
<!--        </div>-->
      </div>
      <div class="informations-container">
        <h2 class="title">Dr Rebecca Norris</h2>
        <p class="sub-title">Dentist</p>
        <p class="registration">GDC registration: 191041</p>
        <div class="more-information">
          <!-- <div class="info-and-date-container">
            <div class="box date">
              <svg class="icon" style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path fill="currentColor"
                  d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z" />
              </svg>
              <p>Availability:</p>
              <br />
              <p>Monday, Tuesday, <s>Wednesday</s>, Thursday & <s>Friday</s></p>
            </div>
          </div> -->
          <p class="disclaimer">
            Rebecca qualified as a dental surgeon from Queens University Belfast in July 2010. Following
            this, she worked in the community dental service, orthodontics and oral surgery as part of
            further training.
            <br />
            <br />
            She has been part of the team at Cloughoge Dental Practice since 2010 where she enjoys all
            aspects of general dentistry.
            <br />
            <br />
            Outside of work she is married with 3 small children. She enjoys cold water swimming and
            has a love for craft and sewing.
          </p>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "DentistCards",
};
</script>
