<template>
  <div class="container">
    <div class="wrapper">
      <div class="box a">
        <img src="../assets/images/missingTooth.jpeg" class="image--cover">
      </div>
      <div class="box b">
        <h1>404</h1>
        <h4>Something's Missing...</h4>
        <h4>The page you're looking for cannot be found</h4>
      </div>
    </div>
  </div>
</template>

<style lang='scss' scoped>
h1 {
  color: #c6006b;
  font-size: 6em;
  padding: 2rem;
}

h4 {
  color: rgb(50, 50, 50);
  font-size: 1.5rem;
  padding: 0 0 1.2rem 2rem;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr))
}

.box {
  padding: 1em;
  margin: 1em;
}

.b {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.image--cover {
  width: 300pt;
  height: 300pt;
  border-radius: 50%;
  margin: 20pt;

  object-fit: cover;
}
</style>