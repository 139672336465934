<template>
  <div class="container-overwrite">
    <div class="bg-title cd-display-container">
      <div class="cd-display-middle">
        <span class="cd-text-white cd-wide cd-cente">
          <h1 class="h1-title" data-aos="fade-right" data-aos-duration="500" data-aos-easing="ease-in-sine">Treatments
          </h1>
        </span>
      </div>
    </div>

    <div class="image-accordion">
      <div class="tab">
        <img
          :src="require('../assets/images/cloughoge/cd-front-vertical.jpeg')"
          sizes="(max-width: 480px) 100vw, (max-width: 768px) 90vw, 30vw"
          alt="cloughoge dental shop front"
          loading="lazy">
        <div class="caption">
          <h2>Great Location</h2>
        </div>
      </div>

      <div class="tab">
        <img
          :src="require('../assets/images/cloughoge/treatment-room.jpg')"
          sizes="(max-width: 480px) 100vw, (max-width: 768px) 90vw, 30vw"
          alt="cloughoge dental shop front"
          loading="lazy">
        <div class="caption">
          <h2>Private Treatment Rooms</h2>
        </div>
      </div>
      
      <div class="tab">
        <img
          :src="require('../assets/images/surgery/surgery-tools-spray.jpg')"
          sizes="(max-width: 480px) 100vw, (max-width: 768px) 90vw, 30vw"
          alt="cloughoge dental shop front"
          loading="lazy">
        <div class="caption">
          <h2>Modern Facilities</h2>
        </div>
      </div>
      
      <div class="tab">
        <img
          :src="require('../assets/images/cloughoge/treatment-room-chair.jpg')"
          sizes="(max-width: 480px) 100vw, (max-width: 768px) 90vw, 30vw"
          alt="cloughoge dental shop front"
          loading="lazy">
        <div class="caption">
          <h2>Patient Comfort</h2>
        </div>
      </div>
      
      <div class="tab">
        <img
          :src="require('../assets/images/cloughoge/products.jpg')"
          sizes="(max-width: 480px) 100vw, (max-width: 768px) 90vw, 30vw"
          alt="cloughoge dental shop front"
          loading="lazy">
        <div class="caption">
          <h2>Range of Products</h2>
        </div>
      </div>

    </div>

    <div class="wrapper">
      <h3>Standard Treatments</h3>
      <div class="table" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1000">
        <div class="row header">
          <div class="cell">Treatment</div>
          <div class="cell">Prices From</div>
          <div class="cell">Description</div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Clinical Exam</div>
          <div class="cell" data-title="Price">£40</div>
          <div class="cell" data-title="Description">
            A general check around all the teeth, checking the hard and soft tissues.
          </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Extensive Clinical Exam</div>
          <div class="cell" data-title="Price">£43</div>
          <div class="cell" data-title="Description">
            An extensive dental health check.
          </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">X-Rays</div>
          <div class="cell" data-title="Price">£10</div>
          <div class="cell" data-title="Description">
            Provides an in depth view of your dental health to help diagnose any problems
            at the earliest stage.
          </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Study Models</div>
          <div class="cell" data-title="Price">£30</div>
          <div class="cell" data-title="Description">
            Provide vital information about your teeth and occlusion. An essential diagnostic
            aid when planning cosmetic dentistry, oral surgery and orthodontics.
          </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Scale and Polish</div>
          <div class="cell" data-title="Price">£45</div>
          <div class="cell" data-title="Description">
            A routine but thorough cleaning of the teeth and gums. A helpful preventative measure
            that can reverse or manage gum disease when already present.
          </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Amalgam Filling</div>
          <div class="cell" data-title="Price">£30 - £60</div>
          <div class="cell" data-title="Description">
            A silver dental filling material used to fill cavities caused
            by tooth decay.
          </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Composite Filling</div>
          <div class="cell" data-title="Price">£45 - £70</div>
          <div class="cell" data-title="Description">
            A white dental filling material used to fill cavities caused
            by tooth decay.
          </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Preventative Sealant</div>
          <div class="cell" data-title="Price">£15 per tooth</div>
          <div class="cell" data-title="Description">
            Thin coatings painted on teeth to protect them and prevent tooth decay.
          </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Root Treatment</div>
          <div class="cell" data-title="Price">£140 - £280</div>
          <div class="cell" data-title="Description">
            Root canal treatment used to treat infection at the centre of a tooth. A non-painful
            treatment that can save a tooth that may otherwise need to be removed.
          </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Crowns</div>
          <div class="cell" data-title="Price">£330</div>
          <div class="cell" data-title="Description">
            A covering for an existing tooth that can improve the way a broken tooth looks,
            as well as making it stronger and longer lasting. Crowns can be made from
            porcelain, ceramic or metal.
          </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Extraction</div>
          <div class="cell" data-title="Price">£50 - £120 per tooth</div>
          <div class="cell" data-title="Description">
            The removal of a tooth or teeth under local anaesthetic that will completely
            block pain from your gums.
          </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Dentures</div>
          <div class="cell" data-title="Price">£200 - £500</div>
          <div class="cell" data-title="Description">
            A complete or partial set of removable false teeth made of acrylic or chrome.
            Built to fit snugly over the gums to replace missing teeth and eliminate potential problems
            caused by gaps.
          </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Veneers</div>
          <div class="cell" data-title="Price">£300</div>
          <div class="cell" data-title="Description">
            A thin moulding, custom-made from procelain that bonds to the front surface of the tooth.
            Veneers are a quick and effective solution to stained, chipped or crooked teeth.
          </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Night Guard</div>
          <div class="cell" data-title="Price">£120</div>
          <div class="cell" data-title="Description">
            Protect teeth from damange by cushioning the effects of clenching while sleeping.
            The cushion barrier helps to prevent chipped or worn down teeth, two of the most
            common problems associated with clenching.
          </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Teeth Whitening</div>
          <div class="cell" data-title="Price">£300</div>
          <div class="cell" data-title="Description">
            Plastic moulds for your teeth that you can fill with a tooth whitening gel for
            whiter, brighter teeth. </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Sports Guard</div>
          <div class="cell" data-title="Price">£65</div>
          <div class="cell" data-title="Description">
            Plastic moulds to protect your teeth during sporting activities. Made to fit.
          </div>
        </div>
      </div> <!-- end of table -->


      <h3>Sundries</h3>
      <div class="table" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
        <div class="row header">
          <div class="cell">Product</div>
          <div class="cell">Prices From</div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Kids Toothbrushes</div>
          <div class="cell" data-title="Price">
            £1.00 - £2.99
          </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Adult Toothbrushes</div>
          <div class="cell" data-title="Price">
            £2.99 - £3.99
          </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Inter-dental brushes</div>
          <div class="cell" data-title="Price">
            £4.60
          </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Disclosing Tablets</div>
          <div class="cell" data-title="Price">
            £2.50
          </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Superfloss</div>
          <div class="cell" data-title="Price">
            £2.99
          </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Flossetts 30 pack</div>
          <div class="cell" data-title="Price">
            £6.40
          </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Ortho Wax</div>
          <div class="cell" data-title="Price">
            £1
          </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Boutique Tooth Whitening Syringes</div>
          <div class="cell" data-title="Price">
            £20
          </div>
        </div>
      </div> <!-- end of table -->

      <h3>DenPlan Options</h3>
      <div class="table" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
        <div class="row header">
          <div class="cell">Treatment</div>
          <div class="cell">Description</div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Option One</div>
          <div class="cell" data-title="Description">
            2 Exams 2 Scale & Polish annual X-Rays PLUS 15% of any private
            treatment. £14.00 per month.
          </div>
        </div>

        <div class="row">
          <div class="cell" data-title="Treatment">Option Two</div>
          <div class="cell" data-title="Description">
            2 Exams 4 Scale & Polish annual X-Rays PLUS 15% of any private
            treatment. £20.00 per month
          </div>
        </div>
      </div> <!-- end of table -->
    </div> <!-- end of wrapper -->
  </div>
</template>

<style lang="sass" scoped>
$color-primary-dark: #4ecdc4 //#066e7a
$color-secondary-light: #066e7a //#4ecdc4
$color-accent-gold: #d5a433
$color-cloughoge: #c6006b

h3
  padding-bottom: 10px
  color: $color-accent-gold

body
  font-size: 14px
  line-height: 20px
  font-weight: 400
  -webkit-font-smoothing: antialiased
  font-smoothing: antialiased

  @media screen and (max-width: 580px)
    font-size: 16px
    line-height: 22px

.wrapper
  margin: 0 auto
  padding: 0 40px
  max-width: 1200px

.table
  margin: 0 0 40px 0
  width: 100%
  display: table
  @media screen and (max-width: 580px)
    display: block

.row
  display: table-row
  background: #fff
  color: #545454

  &:nth-of-type(odd)
    background: #F8F9F9

  &.header
    font-weight: 900
    color: #fff
    background: $color-secondary-light

  &.green
    background: #27ae60

  &.blue
    background: #2980b9

  @media screen and (max-width: 580px)
    padding: 14px 0 7px
    display: block

    &.header
      padding: 0
      height: 6px

      .cell
        display: none

    .cell
      margin-bottom: 10px

      &:before
        margin-bottom: 3px
        content: attr(data-title)
        min-width: 98px
        font-size: 10px
        line-height: 10px
        font-weight: bold
        text-transform: uppercase
        color: $color-cloughoge //title color mobile
        display: block

.cell
  padding: 6px 12px
  display: table-cell
  border-bottom: 1px solid $color-accent-gold

  @media screen and (max-width: 580px)
    padding: 2px 16px
    display: block
    border-bottom: none
</style>

<style>
@media screen and (max-width: 1200px){
  .tab {
    max-width: 90%;
    max-height: 90%;
  }

  .caption h2{
    display: none
  }
}

@media screen and (max-width: 900px){
  .tab {
    max-width: 75%;
    max-height: 75%;
  }
  .caption h2{
    display: none
  }
}

@media screen and (max-width: 600px){
  .tab {
    max-width: 50%;
    max-height: 50%;
  }
  .caption h2{
    display: none
  }
}

/* ---- Create Accordion --- */
.image-accordion{
  max-width: 1080px;
  height: 250px;
  display: flex;
  overflow: hidden;
  margin: 50px auto;
  padding: 0 2rem;
}

.tab{
  position: relative;
  width: 20%;
  height: inherit;
  padding: 20px;
  background: #000;
  color: #FFF;
  cursor: pointer;
  transition: width .5s ease;
}

.tab img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all .5s ease;
}

.caption{
  position: absolute;
  z-index: 2;
  white-space: nowrap;
  top: 150px;
  opacity: 0;
}

.caption h2{
  margin-bottom: 2px;
  text-overflow:clip;
  font-size: 1.5rem;
}

.caption p{
  margin: 0;
  font-family: 'Open Sans';
  font-size: .9rem;
}

/* --- Hover Effects --- */

.tab:hover img{
  opacity: .5;
  object-fit: cover;

}

.tab:hover{
  width: 80%;
}

.tab:hover .caption{
  transition: all .5s ease;
  opacity: 1;
}
</style>
