<template>
  <div>
    <Navigation />
    <!-- Content underneath navbar is pushed down -->
    <router-view />
    <CookieBanner/>
    <Footer />
  </div>
</template>

<script>
import Navigation from "./components/Navigation";
import CookieBanner from "./components/CookieBanner"
import Footer from "./components/Footer.vue";
export default {
  name: "App",
  components: {
    Navigation,
    Footer,
    CookieBanner
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Sora&display=swap");

$color-primary-dark: #066e7a;
$color-secondary-light: #4ecdc4;
$color-accent-gold: #d5a433;
$color-cloughoge: #c6006b;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Sora", sans-serif;
}

// reusable class for views to push further down from nav
.container {
  padding-top: 8em;
  padding-left: 1em;
  padding-right: 1em;
  color: $color-cloughoge;
}
</style>