<template>
  <form action="https://formsubmit.co/f4247e5745805381726afbee08baede0" method="POST">
    <div id="contact-form">
      <div>
        <input type="text" id="name" name="Name" placeholder="Your name" required />
      </div>
    </div>
    <div>
      <div>
        <input type="email" id="email" name="Email Address" placeholder="Your email" required />
      </div>
    </div>
    <div>
      <div>
        <select id="query" name="Query Type">
          <option value="" disabled selected>Type of Query</option>
          <option value="General">General</option>
          <option value="Treatment">Treatment</option>
          <option value="New Registration">New Registration</option>
        </select>
      </div>
    </div>
    <div>
      <div>
        <textarea id="question" name="Question" placeholder="Let us know your question" style="height: 100px"
          required></textarea>
      </div>
    </div>
    <br />
    <div>
      <input type="submit" value="Send Message" />
      <input type="hidden" name="_next" value="https://cloughogedental.com" />
      <input type="hidden" name="_template" value="table" />
      <input type="text" name="_honey" style="display:none">
    </div>
  </form>
</template>

<script>

export default {
  name: 'ContactForm',
}
</script>

<style scoped lang="scss">
$color-primary-dark: #066e7a;
$color-secondary-light: #4ecdc4;
$color-accent-gold: #d5a433;
$color-cloughoge: #c6006b;

input[type="text"],
input[type="email"],
select,
textarea {
  width: 100%;
  padding: 12px;
  resize: vertical;
  border: none;
  border-bottom: 2px solid $color-secondary-light;
}

input[type="submit"] {
  display: inherit;
  background-color: $color-secondary-light;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: $color-cloughoge;
}

input:focus,
textarea:focus {
  outline-width: 0;
}
</style>